var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D E F 6B",
      "164": "A"
    },
    B: {
      "1": "C K L G M N O P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H"
    },
    C: {
      "1": "rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB",
      "2": "7B qB I r 8B 9B",
      "8": "0 1 2 3 4 5 6 7 8 9 J D E F A B C K L G M N O s t u v w x y z AB BB CB DB",
      "328": "EB FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB"
    },
    D: {
      "1": "SB TB UB VB rB WB sB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R S T U V W X Y Z a b e f g h i j k l m n o p q c H uB vB AC BC",
      "2": "I r J D E F A B C K L G M N O s t u",
      "8": "0 1 2 3 4 5 6 7 8 9 v w x y z AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB",
      "584": "PB QB RB"
    },
    E: {
      "1": "K L G yB HC IC zB 0B 1B 2B pB 3B JC KC",
      "2": "I r J CC wB DC",
      "8": "D E F A B C EC FC GC xB nB",
      "1096": "oB"
    },
    F: {
      "1": "FB GB HB IB JB KB LB MB NB OB PB QB RB SB TB UB VB WB XB YB ZB aB bB cB dB eB fB gB d hB iB jB kB lB mB P Q R tB S T U V W X Y Z a b",
      "2": "F B C LC MC NC OC nB 4B PC oB",
      "8": "0 1 2 3 4 5 6 7 8 9 G M N O s t u v w x y z AB BB",
      "584": "CB DB EB"
    },
    G: {
      "1": "eC fC gC hC iC jC zB 0B 1B 2B pB 3B",
      "8": "E wB QC 5B RC SC TC UC VC WC XC YC ZC aC bC cC",
      "6148": "dC"
    },
    H: {
      "2": "kC"
    },
    I: {
      "1": "H",
      "8": "qB I lC mC nC oC 5B pC qC"
    },
    J: {
      "8": "D A"
    },
    K: {
      "1": "d",
      "2": "A",
      "8": "B C nB 4B oB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "c"
    },
    N: {
      "1": "B",
      "36": "A"
    },
    O: {
      "1": "rC"
    },
    P: {
      "1": "tC uC vC wC xB xC yC zC 0C 1C pB 2C 3C 4C",
      "2": "sC",
      "8": "I"
    },
    Q: {
      "1": "yB"
    },
    R: {
      "1": "5C"
    },
    S: {
      "328": "6C"
    }
  },
  B: 2,
  C: "Pointer events"
};
export default exports;